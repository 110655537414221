<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>Change Admin</v-card-title>
        <v-card-text>
          <template>
            <Teacher
              @input="form['accountId'] = $event"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import Teacher from "@/components/Moderator/Teacher/Teacher";
import { mapState } from "vuex";
export default {
  props: ["data", "callbackSuccess"],
  components: {
    Teacher,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[form]
    form: {
      id: null,
      accountId: null,
    },
    fields: [],
    rules: {},
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.sso}/api/v1/en/moderator/institution/changeAdmin`;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    //BOC:[form]
    validate() {
      //this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
  mounted(){
    this.form.id = this.$route.params.parentId
  }
};
</script>

<style>
</style>